.AboutPage {
  
  .m-left {margin-left: 20px;}
  

  #by-the-numbers {
    top: -115px;
  }

  padding: 2em 0.85rem;

  .main__container {
    padding-top: 0;
    margin-top: 1.5rem;
    }

    .leader-title {
      font-size: 2.05rem;
      text-align: center;
      padding: 1rem;
    }

    .leader-social {
      font-size: 1.75rem;
      margin-top: 0.5rem;

      i {
        color: #c7c6c6;
        // margin-left: 1rem;
        
        &:hover {
          color: #fff;
        }
      }
    }

    .email {
      margin-left: 1rem;
    }
    
    .img__container {
      max-width: 900px;
      margin: -0.75rem auto 0 auto;

      img {
        width: 100%;
      } 
  }

  .countup-card-container {
    max-width: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1.3rem;
    margin: 1rem auto 1rem auto;

    .card {
      position: relative;
      background: $bg-gray;
      border: #d3d3d3 solid 1px;
      border-radius: 10px;
      box-shadow: $box-shadow;
      width: 215px;
      margin: 15px 0 25px 0;

      .card-content {
        text-align: center;
        font-weight: 700;
        padding: 21px 10px;
        line-height: 1;
  
        .counter-value {
          font-size: 2.7rem;
          line-height: 1.2;
        }
  
        .sub-content {
          font-size: 1rem;
          font-weight: 600;
        }
      }

      .corner-img {
        position: absolute;
        top: 0;
        left: 0;
        img {
          max-width: 47px;
          border-bottom-right-radius: 10px;
          border-top-left-radius: 10px;
        }
      }
    }
  }  

  .flip-card {
    width: 20rem;
    margin: 0 auto;
    padding: 0.5rem;
  
    &:hover .card__content {
      transform: rotateY(.5turn);
    }
  
    a { text-decoration: none; }
    h3 { margin-top: 5px;}
    .card__content {
      text-align: center;
      position: relative;
      height: 22.5rem;
      transition: transform 2s;
      transform-style: preserve-3d;
    }
    
    .card__front,
    .card__back {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      display: grid;
      align-content: center;
      color: $light-color;
    }
    
    .card__front {
      background-color: rgba(5, 0, 80, 0.466);
      background-size: cover;
      background-repeat: no-repeat;
      background-blend-mode: overlay;
      
      &::before {
        content: '';
        position: absolute;
        --spacer: 1em;
        top: var(--spacer);
        bottom: var(--spacer);
        left: var(--spacer);
        right: var(--spacer);
        border: 3px solid currentColor;
        transform: $level-one;
      }
    }
    
    .card__title {
      transform: $level-one;
      order: 2;
      text-transform: uppercase;
      font-size: $fs-h3-is;
      color: #f3f3f3;
      padding: 5px;
    }
    
    .card__body {
      transform: $level-one;
      font-weight: $fw-normal;
      font-size: 1rem;
      line-height: 1.6;
      color: $light-color;
    
    }
    
    .card__back {
      transform: rotateY(.5turn);
      color: $light-color;
      background: $dark-color;
      
      .cta {
        padding: 0;
        padding-top: 8px;
        &:before {
          top: 6px;
          left: 73.5px;
        }
    
        &:hover {
          &:before {
            width: 45.7%;
          }
        }
      }
    }
  }
}



@import './media.scss';
