$text-font:  'Hind', sans-serif;
$heading-font: 'Hind', sans-serif;

$fs-p: 1rem;
$fs-h1: 3rem;
$fs-h2: 2.4rem;
$fs-h3: 1rem;

$fs-h3-is: 1.4rem;


$fw-light: 300;
$fw-normal: 500;
$fw-bold: 900;



$icons: (
  "percent": "\f295",
  "fund-value": "\f155",
  "clock": "\f017",
  "clients": "\f007",
);
 
$counter-after: (
  "percent": "%",
  "million": " Million",
  "year": " Years",
  "plus": " +"
);