$secondary-color: #00699B;
$primary-color: #539cd2;
$accent-color: #539cd2;

$light-color: whitesmoke;
$gray-color: #6e6e73;
$dark-color: #333;
$darker-color: #1d1d1f;

$bg-light: #f0f0f0f0;
$bg-alt-light: #dfdfdf;
$bg-gray: #d3d3d385;
$bg-dark: #18181a;


$box-shadow: rgba(0, 0, 0, 0.24) 1px 5px 8px;

