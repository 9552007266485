.PressR {
  text-align: center;
    .card-grid-responsive {
      .card {
          .card-content {
            padding: 0.5rem;
          }
          h4 {
            height: 85px;
          }
      }
    }
}