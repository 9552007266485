.AboutPage {
  @media screen and (max-width: 1325px) {
    .flip__grid {
      max-width: 1000px;
    }
  }
  
  @media screen and (max-width: 1000px) {
    .countup-card-container {
      grid-template-columns: 1fr 1fr;

      .card {
        margin: 9px 0;
      }
    }
  }
  
  @media screen and (max-width: 550px) {
    
      .img__container {
        img {
          display: none;
        }
        margin-bottom: 1.5rem;
      }
  }
  
  @media screen and (max-width: 500px) {

  

  
      .AboutPage {
        margin-top: 150px;

        .countup-card-container {
          margin-top: 0.5rem;

          .card {
            margin-bottom: -1rem;
          }
        }
                
      }

      .flip-card {
        padding: 0 1.5rem;
        .card__content {
            height: 20rem;
          }
       }  
  
  
    .countup-card-container {
      column-gap: 0.85rem;
      margin-bottom: 1.5rem;
      .card {
        width: 181px;
        margin: 11px 0 5px 0;
        padding: 0;

        .card-content {
          font-weight: 600;
          padding: 10px 4px;
          line-height: 1;

          .counter-value {
            line-height: 1.2;
            font-size: 1.9rem;
          }

          .counter-after-plus:after {
            font-size: 1.15rem;
          }
        }
        .corner-img {
          position: absolute;
          top: 0;
          left: 0;
          img {
            max-width: 40px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .AboutPage {
    .countup-card-container {
      display: flex;
      flex-direction: column;
  
      .card {
        width: 225px;
        padding: 0.5rem;

        .card-content {
          .counter-value {
            font-size: 2.25rem;
          }
    
          .sub-content {
            font-size: 1.15rem;
            font-weight: 600;
          }
        }
      }
    }
  } 
}