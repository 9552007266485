@media screen and (max-width: 1600px) {

  iframe { width: 35rem; height: 19rem; }
  
}
  
@media screen and (max-width: 1450px) {
  .outer-container  {
    max-width: 1300px;
  }
}

@media screen and (max-width: 1350px) {
  iframe { width: 31rem; height: 16rem; }

  .partners img {margin: 0 2.5rem;}
}

@media screen and (max-width: 1100px) {

  iframe { width: 28rem; height: 13rem; }
    
  .inner__container {
    .container {
      img {
        margin: 40px 0;
        width: 450px;
      }
    }
  }
  .container {
    padding: 0 25px;
  }
  
 
  
  .features {
    
    .grid-features-container {
      padding: 3.3rem 1.5rem;
      .img-box {
        display:none;
        img {display: block;}
      }
    }
    
  }
}

@media screen and (max-width: 950px) {

  iframe { width: 27rem; height: 12rem; }
}

@media screen and (max-width: 1020px) {

  .features {display: none;}
  .features-mb { display: flex;}
  iframe { width: 30rem; height: 18rem; }



  .inner__container {
    text-align: center;
    .grid-container {
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      background: url("../../../assets/images/solutions/Wave-mb.png") no-repeat center right / cover;

      .box {
        padding: 0;
        padding-top: 1.5rem;

        p {
          margin-bottom: 100px;
        }
      
      }

      
    }
  }
  
  .container {
    .box {
      padding: 0 10px;
    }
  }

  .intro-sec {
    padding: 0 1rem !important;
  }

  .features-mb {
    border-top: #a3a2a25d solid 3px;
    border-bottom: #a3a2a25d solid 3px;
  
    .grid-features-container-mb {
      display: flex;
      flex-direction:column; 
      place-items: center;
      margin: 50px 0;

      .box-1 {
        margin: 0 15px 0 49px;
        .bullet {
          text-align: left;
          position: relative;
          &::before {
            content: url("../../../assets/images/solutions/bullet-img.png");
            display: inline-block;
            position: absolute;
            top: 0;
            transform: translate(-125%, 30%);
          }
        }
      }

      .box-2 {
        text-align: right;
        margin: 0 57px 0 10px;
        .bullet {
          position: relative;
          &::before {
            content: url("../../../assets/images/solutions/bullet-img.png");
            display: inline-block;
            position: absolute;
            top: 0;
            transform: translate(25%, 30%);
          }
        }
      }
    }

    
    .img-box {
      img {
        margin: 40px 0;
        padding-right: 30px;
        max-width: 450px;
      }
    }

  }
 
  .inner__container {
    .container {
      img {
        width: 250px;
      }
    }
  }

  .partners {

    .grid-partners-container {
     
      .box {
        margin: 0;
          img {
          margin: 0 0.75rem;
          display: block;
          width: 8rem; 
          }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .intro-sec {
    .intro-container {
      grid-template-columns: repeat(1, auto);
      justify-content: center;
    }
  }

  .partners {
    .grid-partners-container {
      display: grid;
      grid-template-columns: 1fr 1fr;  

      .box {
      img {
        margin: 0 2.5rem;
        display: block;
        width: 7rem; 
      }
    }
    }
  }
}


@media screen and (max-width: 550px) {

  iframe { width: 23rem; height: 14rem; }

  .inner__container {
    .grid-container {
      padding-top: 1rem;
      .box {
       padding: 0;
       margin: 0;
      }
      
    }
  }

  .features-mb {
    .img-box {
      img {
        max-width: 350px;
      }
    }
  }

  .partners {
    .grid-partners-container {
      display: grid;
      grid-template-columns: 1fr 1fr;  

      .box {
      img {
        margin: 0 .2rem;
        display: block;
        width: 7rem; 
      }
    }
    }
  }
}

@media screen and (max-width: 500px) {

  iframe { width: 19rem; height: 12rem; }

  .img-box {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    img {
      width: 90%;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 400px) {

  iframe { width: 190px; height: 130px; }


  .features-mb {
    padding: 0 0.25rem;
    .img-box {
      img {
        max-width: 190px;
      }
    }
  }

  .title-box {
    text-align: center;
    margin: 0 auto;
    line-height: 1.2;
  }

  .partners {
    .grid-partners-container {
      display: grid;
      grid-template-columns: 1fr 1fr;  

      .box {
      img {
        margin: 0 .15rem;
        display: block;
        width: 5.35rem; 
      }
    }
    }
  }

  .Solutions {
    .solution-boxes {
      .card-grid-responsive {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .motion-card {   padding: 0.9rem 0.55rem; }
      }
    }
  }
  
}

