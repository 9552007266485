.Privacy {
  padding: 0 10%;
  margin: 0 auto;

  h1 {
    border-bottom: 2px solid black;
    margin-bottom: 1.75rem;
  }

  .p-width {
    margin: 0 auto;
    max-width: 1100px;
    padding: 0 7%;
  }

  li {
    margin-left: 2.25rem;
  }
}

.accordion {
  max-width: 80vw;
  margin: auto;

  .motion-card {
    margin: 1rem;
  }
}

@media screen and (max-width: 800px) {

  .Privacy {
    padding: 0 5%;
    h1 {
      font-size: 2.75rem;
    }
  }

  .accordion {
    max-width: 90vw;

    h2 {
      margin: 2rem 0 1rem 0;
      font-size: 2.25rem;
    }
  
    .motion-card {
      margin: 0 0.75rem;
      margin-bottom: -13px;

      h3 {
        padding-right: 10px;
      }
    }
  }
} 