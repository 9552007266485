@media screen and (max-width: 450px) {
  .Blog {

    .blog-container {
      display: flex;
      flex-direction: column-reverse;
      margin: 0;
      padding: 0;

      .blog-categories {
        margin: 0 auto;
        padding-top: 0.5rem;

        .cat-list {
          margin-left: 0.55rem;
        }
      }

      .container {
        margin: 0 auto;
        padding: 0;

        .blog__grid {
          max-width: min-content;
        }
      }
    }

    
    
  }
  
  }
