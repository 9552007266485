.Page404 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;


  .main-content {
    display: flex;
    align-items: center;
    
    .logo {
      width: 130%;
      img {
        width: 90%;
      }
    }

    .content {
      width: 100%;
      h1 {
        font-size: 12rem;
        padding: 0;
        margin: 0;
        line-height: 0.85;
        font-weight: 400;
      }

      h2 {
        padding: 0;
      }

      p {
        font-size: 1.55rem;
        padding-bottom: 1.75rem;
      }

      .btn {

        font-size: 1.75rem;
        padding: 0.45rem 2.75rem;
      }
    }
  }
}