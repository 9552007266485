.slide img {
  width: 190px;
  height: 190px;
  margin: 0 auto;
  padding: 0.5rem;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  font-size: 1.5rem;
  color: $secondary-color;
  transition: color 300ms;
}

.arrow svg:hover {
  color: $primary-color;
}

.next {
  right: -4%;
  top: 46.5%;
}

.prev {
  left: -4%;
  top: 46.5%;
}


@media screen and (max-width: 1500px) {
  .slide img {
    padding: 0.5rem;
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }

  .client-slide {
    width: 85vw;
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .slide img {
    padding: 0.25rem;
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }

  .client-slide {
    width: 90vw;
    margin-top: 0.75rem;
  }
}
