.features-mb {display: none;}
.active {font-weight: 600;}
.spacer {padding-top: 50px; padding-bottom: 80px; }

.margin-b {margin-bottom: 1.5rem;}

.text-right {text-align: right;}
.text-left {text-align: left;}
.text-center {text-align: center;}

.Solutions {
  .solution-boxes {
    .card-grid-responsive {
      width: clamp(280px, 100%, 1200px);
      grid-template-columns: repeat(auto-fit, minmax(20.35rem, max-content));
      justify-content: space-around;
    }
  }
}



.main__container {
  max-width: 1350px;
  margin: auto;
}

.Solutions {
  .scroll-offset {
    top: -130px;
  }
}

// Intro Styling

.intro-sec {
  margin: 0 auto;

  .intro-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    line-height: 1.6;
    margin: 2.5rem 0 0 0;
  
    .main-content {
      font-weight: 500;
      margin-bottom: 25px;
    }
  
    li {
      list-style-type: none;
      color:$light-color;
      }
  }
}

// List of items

.view_item {
  display: flex;
	align-items: center;
  height: 4rem;

  a {
    text-decoration: none;
  }

  i {
    font-size: 0.75rem;
  }

  img {
    width: 35px;
    border-radius: 100px;
    margin-right: 25px;
  }

  .vi_left {
    margin-left: 25px;
  }

  
  .content{
    color: #06c;
    margin-bottom: 10px;
    font-size: 1.25rem;

    &:hover {
      font-weight: 600;
    }
  }
}

// Outer box with arrow

.outer-container {
  position: relative;
  background: $secondary-color;
  max-width: 1500px;
  border-radius: 15px;
  margin: auto;
  margin-bottom: 1rem !important;
  padding: 20px 15px;

}

.arrow-top::after {
    content: " ";
    position: absolute;
    right: 25px;
    top: -19px;
    border-top: none;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    border-bottom: 20px solid $secondary-color;
  }


// Video & text


.inner__container {
  background-color: #fff;
  color: $dark-color;

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    align-items: center;
    justify-content: space-between;
    background: url("../../../assets/images/solutions/wave.png") no-repeat center / cover;
    padding: 1.5rem 1.5rem 3rem 1.5rem;

    .box {
      color: $light-color;

      p {color: $light-color;}

    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 1.25rem;


    img {
      max-width: 150px;
    }
  }

}


// Features


.features {
  color: $dark-color;
  background-color: #fff;
  border-top: #a3a2a25d solid 3px;
  border-bottom: #a3a2a25d solid 3px;
  padding-bottom: 1.5rem;

  .grid-features-container {
    max-width: 1300px; 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    margin: 2.5rem auto 0 auto;

    
    .img-box {
      display: flex;
      margin: auto;

      img {
        width: 100%;
      }
    }

    .box {
      width: 325px;
      margin: auto;
      .bullet-right {
        position: relative;
        height: 200px;
        &::after {
          content: url("../../../assets/images/solutions/bullet-img.png");
          display: inline-block;
          position: absolute;
          top: 0;
          transform: translate(75%, 30%);
        }
  
      }

      .bullet {
        position: relative;
        height: 200px;

        &::before {
          content: url("../../../assets/images/solutions/bullet-img.png");
          display: inline-block;
          position: absolute;
          align-items: center;
          top: 0px;
          transform: translate(-170%, 30%);

        }
  
      }
    
      h3 {
        margin-bottom: 10px;
      }

    }
  }
}

// Trusted Partners

.title-box {
  text-align: center;
  margin: 0 auto;
  line-height: 1.2;
}

.partners {
  margin: 0 auto;

  img {
    margin: 0 4rem;
    display: block;
    width: 11rem;

    
  }

  .grid-partners-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 auto;
  }
  
}

@import './media';
