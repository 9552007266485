.sm-margin__botton {
  margin-bottom: 1.25rem;
}

.SingleBlog {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
  margin-top: -1rem;
  padding: 2rem 0;

  h1 {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 600;
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0 0 1.5rem 0;
  }

  p {
    margin-bottom: 2rem;
  }

  ul {
    padding-left: 1.75rem;
    margin-bottom: 2rem;

    li {
      font-size: 1.195rem;
    }
  }

  .blog-video {
    display: flex;
    align-items: center;
    justify-content: center;
      iframe {
      width: 80% !important;
      height: 28rem !important;
    }
  }

  .border-top {
    border-top: 2px solid gray;
    margin-top: 1.25rem;

    span {
      display: block;
    }
  }

  .author {
    font-style: italic;
    margin-top: 1.25rem;
  }

  .disclaimer {
    font-size: 0.8rem;
    margin: 1.25rem 0;

  }
  .single-blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .single-blog-content {
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    // background-color: $bg-light;
    // transform: translate(0, -18%); 
    padding: 3em 3.5em;
    border-radius: 15px;
    // box-shadow: $box-shadow;

    .blog-video {
      margin: 2em 0;
    }

    p {
      text-align: justify;
    }

    img {
      width: 85%;
      margin: 0 auto;
      height: 60vh;
      border-radius: 15px;
      margin-bottom: 1rem;
    }
  }

  .single-blog-date {
    font-weight: 600;
    font-size:  1rem;
    text-align: center;
    margin-bottom: 1.5em;
   }
  .single-blog-top-banner {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 50vh;
    overflow: hidden;
    background-color: $primary-color;
    
  }

  @media screen and (max-width: 500px) {

    h1 {
      font-size: 2.8rem;
    }

    h2 {
      font-size: 2rem;
    }
    .blog-video {
      display: flex;
      align-items: center;
      justify-content: center;
        iframe {
        width: 100% !important;
        height: 13.5rem !important;
      }
    }
  }
}