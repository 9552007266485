.contact {  
  max-width: 1100px;
  margin: 0 auto;
  padding: 2rem 1rem 1.75rem 1rem;

}

.contact-box {
  width: clamp(100px, 90%, 810px);
  margin: 0 auto;
  // box-shadow: $box-shadow;
  // background-color: $bg-gray;

  // border-radius: 15px;

  .contact-form {
    width: 100%;
    padding: 4.5% 4.5% 2.5% 4.5%;
    background-color: $bg-gray;
    // background-color: #f3f3f3;
    border-radius: 15px;
    

    .form-container {
      display: flex;
      gap: 5%;
      .form-details {
        width: 50%;
      }

      .form-message {
        width: 50%;
        .form-item {
          height: 100%;
        }
      }  
    }

    .submit-container {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      padding-top: 1rem;


      input {
        background-color: transparent;
        border: none;
        color: $light-color;
        cursor: pointer;
        padding: 0;
        font-size: calc(#{$fs-p} + 0.155rem);
      }
    }
    
    .form-item {
      position: relative;
    }

    label {
      color: $dark-color;
      font-size: clamp(14px, 1.5vw, 16px);
      pointer-events: none;
      user-select: none;
      font-family: $text-font;
    }
    
    input, textarea {
      width: 100%;
      height: 90%;
      outline: 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px;
      font-size: clamp(14px, 1.5vw, 16px);
      font-family: $text-font;
    }

    // input:focus+label, 
    // input:valid+label, 
    // textarea:focus+label, 
    // textarea:valid+label {
    //   font-size: clamp(13px, 1.3vw, 14.5px);
    //   color: $dark-color;
    //   top: -21.5px;
    //   transition: all .225s ease;
    // }
  } 

}

@media only screen and (max-width: 750px) {
  .contact-box {
  
    .contact-form {
      
      label {
        top: 11px;
      }
  
      input:focus+label, 
      input:valid+label, 
      textarea:focus+label, 
      textarea:valid+label {
        top: -17px;
      }
    } 
  
  }
}



@media only screen and (max-width: 650px) {
  .contact-box {
  
    .contact-form {
      padding: 7% 4.5% 2.5% 4.5%;
      .form-container {
        display: flex;
        flex-direction: column;
        gap: 6%;

        .form-details {
          width: 100%;
        }
  
        .form-message {
          width: 100%;
        }
      }

      input:focus+label, 
      input:valid+label, 
      textarea:focus+label, 
      textarea:valid+label {
        font-size: clamp(13px, 1.3vw, 16px);
        color: $dark-color;
        top: -18px;
        transition: all .225s ease;
      }
    }
  }
}
