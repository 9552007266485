@media screen and (max-width: 1400px) {
  .HomePage {

    video {
      height: 88vh;
    }
  }
}



@media screen and (max-width: 600px) {
  .HomePage {

    .home-content {
      margin-top: -0.5rem;
      margin-bottom: 1rem;

      video {
        height: 85.55vh;
      }
    }
  }
}