.Press {
  .main-content {
    text-align: center;
    margin-bottom: 1rem;
    h3 {
      padding-bottom: 0;
    }
    p {
      color: $primary-color;
      font-weight: 600;
    }

    span {
      font-size: 0.9rem;
      font-weight: 600;
    }

    .typing {
      color: $primary-color;
    }
  }

  .year-date {
    display: flex;
    //max-width: 400px;
    justify-content: center;
    //margin: 0 auto 1rem auto;
    padding: 0 1.75rem;

    .btn {
      padding: 0.2rem 1.3rem;

      &:active{
        background-color: $secondary-color;
        color: $light-color;
      }
      &:focus{
          background-color: $secondary-color;
          color: $light-color;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  h1 {
    line-height: 1.15;
  }

  .Press {
    .year-date {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 auto;
  
      .btn {
        padding: 0;
        width: 75px;
        margin: 3px auto;
  
        &:active{
          background-color: $primary-color;
          color: $light-color;
        }
        &:focus{
            background-color: $primary-color;
            color: $light-color;
        }
      }
    }
  }
}

