.HomePage, .AboutPage, .Solutions, .Insider, .FAQ {
  padding: 2em 1rem;
}

body {
  margin-top: 1.25rem;
}


.bg-light {
  background-color: rgba(2, 145, 255, 0.05);
  padding: 0 1.5rem;
  margin: 0 -2rem;
}
 
.p__x {
  padding: 0 1.25rem;
}

.p__y {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pb-clr {
  padding: 0px;
}

.m__y {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.m__top {
  margin-top: 2.5rem;
}


.m__bottom {
  margin-bottom: 1.5rem;
}



.clip {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #00B9DF;

}
::-webkit-scrollbar-thumb {
  background: #D0F5FF;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #3E9ED7;
}

.scroll-offset {
  display: block;
  position: relative;
  top: -110px;
  color: black;
}

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text__center {
  text-align: center;
}

.max__width {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
}

.container-text__width {
  max-width: 1150px;
  margin: 0 auto;
}

.card-grid-responsive {
  display: grid;
  width: clamp(350px, 100%, 1350px);
  grid-template-columns: repeat(auto-fit, minmax(20rem, max-content));
  font-family: $heading-font;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 11px;
  
  .card {
    width: 310px;
    text-align: center;
    overflow: hidden;
    padding: 0;
    background-color: $bg-alt-light;
    border-radius: 10px;
    box-shadow: $box-shadow;
    padding-bottom: 0.7rem;
    margin: 4px auto;
  
      .card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 0.35rem;
      }
    
      h4 {
        display: flex;
        justify-content: center;
        height: 60px;
        align-items: center;
        margin: 0 auto;
        line-height: 1.3;
      }
    
      .card-content-date {
        font-size: 0.85rem;
        span {
          font-weight: 600;
        }

        i {
          margin-left: 10px;
        }
      }

      .card-content-info {
        i {
          font-size: 0.8rem;
          margin-left: 10px;
        }
        span {
          font-weight: $fw-bold;
          font-size: 0.9rem;
        }
      }
    
      .top-img {
        img {
          width: 100%;
          height: 160px;
        }
      }

      .btn {
        margin: 0.5rem auto;
      }
  }
}

@media screen and (max-width: 600px) {
  body {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 350px) {
  .card-grid-responsive {
    width: clamp(295px, 100%, 1350px);
    
    .card {width: 290px;}
  }

}

