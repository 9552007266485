footer { 
  background-color: $primary-color;
  padding: 1rem 1rem 0.15rem 1rem;
  color: #f3f3f3;
  line-height: 1.6 ;

  span {
    margin-top: 5px;
    text-align: center;
    font-size: 12.5px;
  }

  .footer-container {
    max-width: 1300px;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    width: 83vw;
    margin: 0 auto;
    justify-content: center;
    padding-bottom: 0.75rem;
  
    .contact-title {
      font-size: 18px;
      // color: #f3f3f3;
    }
  
    .footer-logo {
      display: grid;
      align-items: center;
      justify-content: center;
      
      img {
        width: 72.5%; 
        margin: 0 auto;
      }
    }
  
  
    .footer-contact {
      display: flex;
      margin: 0 auto;

  
      .footer-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.2rem;
    
        .info__container {
          display: flex;
          margin: auto 0;
    
          a {
            text-decoration: none;
            margin: auto 0;
            // color: #f3f3f3;
          }
    
          p {
            font-size: 16px;
            color: #f3f3f3;
          }
    
          i {
            margin: auto 0;
            font-size: 19px;
            padding-right: 10px;  
            color: #f3f3f3;
          }
        }
    
        
      }
    }
  
    .footer-social {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .info-links {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          span {
            display: block;
          }

          a {
            // color: $light-color;
            text-decoration: none;
            margin-right: 4px;
          }
        }
      
      }
    }
}

.social-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5rem;

    div {
      list-style: none;

        a {
          display: block;
          width: 37px;
          height: 37px;
          border-radius: 50%;
          background-color: #f3f3f3;
          margin: 0 0.72rem;
          text-align: center;
          transition: .6s;
          box-shadow: 0 5px 4px rgba(15, 15, 15, 0.5);

          &:hover{
            transform: translate(0, -10%);
          }
        }

        &:nth-child(1) a:hover{
          background-color: #3b5998;
        }

        &:nth-child(2) a:hover{
          background-color: #00acee;
        }

        &:nth-child(3) a:hover {
          background-color: #0e76a8;
        }
      }
    
    .fab {
      font-size: 19px;
      margin-top: -11px;
      margin-left: 1.5px;
      line-height: 60px;
      transition: .3s;
      color: rgb(58, 58, 58);

      &:hover {
        color: #fff;
      }
    }
  
}

@media screen and (max-width: 1200px) {

  footer {
    .footer-container {
      width: 96.25vw;
    }
  }
}

@media screen and (max-width: 900px) {
    footer {
    .footer-container {
      display: flex;
      width: 96.25vw;

      .footer-logo {
        display: none;
      }
      }
    }
  }

@media screen and (max-width: 750px) {
  footer {
    
    .footer-container {
      width: 95.5vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      .footer-contact {
        .footer-grid {
          justify-content: center;
          
          .info__container {
            padding-bottom: 0.75rem;
          }
        }
      }

     

      .footer-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 1.5rem 0;
        img {
          width: 190px;
        }
      }

      .footer-social {
        margin: 16px 0 16px 0;
        .contact-title {
          margin: 0.85rem 0;
        }
      }
      }
    }
}

@media screen and (max-width: 530px) {
  footer {
    
    .footer-container {
      width: 91vw;
      .footer-contact {
        .footer-grid {
          grid-template-columns: 250px !important;
          margin-top: 0.85rem;
          gap: 0.7rem
        }
      }
     
    }
  }  
}
