.solution-boxes {
  .card-grid-responsive {
    width: clamp(295px, 100%, 1050px);
    grid-template-columns: repeat(auto-fit, minmax(19.5rem, max-content));

    a {text-decoration: none;}
  }
}

.motion-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $bg-gray;
  color: $dark-color;
  border: #d3d3d3 solid 1px;
  padding: 0.9rem 1.1rem;
  border: none;
  margin: 0 0.3rem;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background: #ededede0;
    transform: scale(1.02);
  }

  .title-content {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto 0;
  }

  .title-content-faq {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin: auto 0;
  }

  h3 {
    cursor: pointer; 
  }

  img {
    width: 30px;
    height: 30px;
  }

  p {
    margin-top: 1rem;
  }

  span {
    position: absolute;
    top: 8.5px;
    margin-left: 5px;
  }


}
