#companies {
  margin-bottom: 3.2rem;  
}

.honeycomb {
    max-width: 987px;
    margin: 0px auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 9.05rem;
    grid-gap: .56rem;
}

.honey-m {
  display: none;
}
  
.hexagon {
  display: flex;
  width: 190px;
  height: 195px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  
  // First row
  &:first-child{
    grid-row-start: 1;
    grid-column: 2 / span 2;
  }
  &:nth-child(2){
    grid-row-start: 1;
    grid-column: 4 / span 2;
  }
  &:nth-child(3){
    grid-row-start: 1;
    grid-column: 6 / span 2;
  }
  &:nth-child(4){
    grid-row-start: 1;
    grid-column: 8 / span 2;
  }
  &:nth-child(5){
    grid-row-start: 1;
    grid-column: 10 / span 2;
  }

  // Second Row
  &:nth-child(6){
    grid-row-start: 2;
    grid-column: 3 / span 2;
  }
  &:nth-child(7){
    display: none;
    grid-row-start: 2;
    grid-column: 3 / span 2;
  }
  &:nth-child(8){
    grid-row-start: 2;
    grid-column: 5 / span 2;
  }
  
  &:nth-child(9){
    grid-row-start: 2;
    grid-column: 7 / span 2;
  }
  &:nth-child(10){
    grid-row-start: 2;
    grid-column: 9 / span 2;
  }

  // Third Row
  
  &:nth-child(11){
    grid-row-start: 3;
    grid-column: 2 / span 2;
  }
  &:nth-child(12){
    grid-row-start: 3;
    grid-column: 4 / span 2;
  }
  &:nth-child(13){
    grid-row-start: 3;
    grid-column: 6 / span 2;
  }
  &:nth-child(14){
    grid-row-start: 3;
    grid-column: 8 / span 2;
  }
  &:nth-child(15){
    grid-row-start: 3;
    grid-column: 10 / span 2;
  }
  

  .cta {

    &:before {
      width: 22px;
      height: 20px;
      top: 18px;
      left: 8px;
    }

    span {
      font-size: 0.7rem;
      font-weight: $fw-bold;
    }
    &:hover {
      &:before {
        width: 83%;
      }
    }
   
  }
}

.hexagon__content {
  width: 100%;
  position: relative;
  transition: transform 2s;
  transform-style: preserve-3d;
}

.hexagon__content_noflip {
  width: 100%;
  position: relative;
}

.hexagon:hover .hexagon__content {
  transform: rotateY(.5turn);
}

.hex__front,
.hex__front__soon,
.hex__back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  display: grid;
  align-content: center;
}

.hex__front {
    background: $accent-color;
    -webkit-animation: AnimationName 8s ease infinite;
    animation: AnimationName 8s ease infinite;
    
    .hex-img {
      width: 150px;
      height: 150px;
      margin: 0 auto;
    }
    
}
.hex__front__soon {
  background: #bababa;
  -webkit-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
  
  .hex-img {
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }
  
}

.hex__body {
  transform: $level-two;
  text-align: center;
  padding: 1.35rem 0.25rem 0 0.25rem;

  
  p {
    font-weight: $fw-light;
    color: $light-color;
    font-size: 0.9065rem;
    line-height: 1.1;
    margin-bottom: 0.195rem;
  }
  
}

.hex__back {
    padding: 0 1rem;
    transform: rotateY(.5turn);
    color: var(--clr);
    background: #333;
}


@media screen and (max-width: 1060px) {
  
  .honeycomb {
    display: none;
  }
  
  .honey-m {
    max-width: 580px;
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 6.25rem;
    grid-gap: 0.3rem;
  }

  .hexagon {
    width: 113px;
    height: 133px;
  }

  .hex__front {
    
    .hex-img {
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }
    
}
}

@media screen and (max-width: 640px) {

  #companies {
    margin-bottom: 2.3rem;  
  }

  .honeycomb {
    display: none;
  }
 
  
  .honey-m {
    max-width: 355px;
    margin: 0 auto;
    margin-top: 10px;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 5.935rem;
    grid-gap: 0.35rem;
  }
  
  .hexagon {
    width: 115px;
    height: 130px;
    // First row
    &:first-child{
      grid-row-start: 1;
      grid-column: 1 / span 2; 
    }
    &:nth-child(2){
      grid-row-start: 1;
      grid-column: 3 / span 2;
    }
    &:nth-child(3){
      grid-row-start: 1;
      grid-column: 5 / span 2;
      }
    
    // Second Row
    &:nth-child(4){
      grid-row-start: 2;
      grid-column: 2 / span 2;
    }
    &:nth-child(5){
      grid-row-start: 2;
      grid-column: 4 / span 2;
      }
    
    // Third Row
    &:nth-child(6){
      grid-row-start: 3;
      grid-column: 1 / span 2;
    }
    &:nth-child(7){
      display: flex;
      grid-row-start: 3;
      grid-column: 3 / span 2;
      }
    
    &:nth-child(8){
      grid-row-start: 3;
      grid-column: 5 / span 2;
    }

    // Fourth Row
    &:nth-child(9){
      grid-row-start: 4;
      grid-column: 2 / span 2;
    }
    &:nth-child(10){
      grid-row-start: 4;
      grid-column: 4 / span 2;
      }

    // Fifth Row
    &:nth-child(11){
      grid-row-start: 5;
      grid-column: 1 / span 2;
    }
    &:nth-child(12){
      grid-row-start: 5;
      grid-column: 3 / span 2;
      }
    
    &:nth-child(13){
      grid-row-start: 5;
      grid-column: 5 / span 2;
    }

    // Sixth Row
    &:nth-child(14){
      grid-row-start: 6;
      grid-column: 2 / span 2;
    }
    &:nth-child(15){
      grid-row-start: 6;
      grid-column: 4 / span 2;
      }
    
      .cta {

        &:before {
          width: 25px;
          height: 25px;
          top: 17px;
          left: 8.5px;
        }
  
        span {
          font-size: 0.7rem !important;
          font-weight: $fw-bold;
        }
        &:hover {
          &:before {
            width: 83%;
          }
        }
       
      }

    p {
      font-size: 0.75rem !important;
    }

    
  }
    
}


@media screen and (max-width: 400px) {
  .honey-m {
    max-width: 280px;
    grid-auto-rows: 4.8rem;
    grid-gap: 0.29rem;
  }
  
  .hexagon {
    width: 90px;
    height: 105px;
  }

  .hex__front {
    
    .hex-img {
      width: 80px;
      height: 80px;
      margin: 0 auto;
    }
    
}
}


