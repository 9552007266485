.btn {
  background: $primary-color;
  font-family: $text-font;
  font-size: 1.155rem;
  font-weight: 500;
  color: $light-color;
  border: none;
  // border: 2px solid $primary-color;
  border-radius: 10px;
  padding: .35rem 1.65rem;

  &:hover {
    color: $light-color;
    background: $secondary-color;
    // border: 2px solid $primary-color;
    transition: 0.4s;
    cursor: pointer;
  }
}


.cta {
  position: relative;
  padding: 16px;
  transition: all .2s ease;
  text-decoration: none;
  color: $light-color;
  
  &:before {
    content: "";
    position: absolute;
    top: 13px;
    left: 3px;
    display: block;
    border-radius: 28px;
    // background: rgba(rgb(0, 0, 0),.5);
    width: 30px;
    height: 30px;
    transition: all .3s ease;
  }
  span {
    position: relative;
    font-size: 0.9rem;
    font-weight: $fw-bold;
    text-transform: uppercase;
    vertical-align: middle;
  }
  
  &:hover {
    color: $dark-color;
    &:before {
      width: 93%;
      background: rgba($light-color,1);
    }
  }
  &:active {
    transform: scale(.96);
  }
}
