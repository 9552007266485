.Insider {

  .main-content {
    display: flex;
    text-align: center;
    max-width: 1100px;
    margin: 0 auto;
    .content {
      display: grid;
      align-items: center;
      padding: 0 0.85rem;
    }
  }

  .card-grid-responsive {
    width: clamp(100px, 100%, 1175px);
    grid-template-columns: repeat(auto-fit, minmax(20rem, max-content));
    gap: 25px;

    .card {
      background-color: $bg-alt-light;
      border-radius: 10px;
      box-shadow: $box-shadow;

      .card-content {
        padding: 0 .8rem;
        overflow: hidden;
        justify-content: space-between;
      }

      p {
        display: none;
        margin-top: 0.25rem;
        height: 100px;
        overflow: hidden;
      }

      button {
        margin: 15px 0;
      }

      .top-img {
        img {
          width: 100%;
          height: 12.5rem;
          border-radius: 10px 10px 0 0;
          margin-bottom: -7px;
        }
      }
    }    
  }

  .newsletters {
    text-align: center;
    margin: 0 auto;

    .title-date {
      margin: 2rem auto 1rem auto;

      h3 {
        display: inline;
        font-size: 1.25rem;
      }

      a {
        text-decoration: none;
      }

      i {
        margin-top: 20px;
      }
      
    }
    .card {
      h4 {
        height: 40px;
        margin-top: 0.75rem !important;
        font-size: $fs-h3;
        padding: 0;
        margin-top: 0;
      }
    }
   

    
  }
}

@media screen and (max-width: 350px) {
  .Insider {
    .newsletters {
      .title-date {
        h3 {
          font-size: 1.15rem;
        }
      }
    }
  }  
}