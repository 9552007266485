@tailwind base;
@tailwind components;
@tailwind utilities;
@import './styles/abstracts/index';
@import './styles/base/index';
@import './styles/components/index';
@import './styles/pages/home/index';
@import './styles/pages/404/index';
@import './styles/pages/industry/index';
@import './styles/pages/about/index';
@import './styles/pages/news/index';
@import './styles/pages/faq';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// Header Investor Relations Icon

.MuiTab-wrapper {
  flex-direction: row !important;

  svg {
    font-weight: bold;
    margin-left: 5px;
  }
}

.btn:focus {
  outline: none;
  box-shadow: none;
}
