/* nav */
.header {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.nav {
  position: relative;
  display: flex;
  height: 5.3rem;
  align-items: center;
  justify-content: flex-end;
  margin: auto;
  z-index: 1;

  .logo {
    position: absolute;
    left: 0;
    cursor: pointer;    
    img {
      width: 9rem;
    }
  }

  a {
    text-decoration: none;
    color: $dark-color;
  }

  ul {
    list-style: none;
  }

  i {
    padding: 2px 0 0 5px;
  }

}


.menu-items {
  display: flex;
  align-items: center;
  font-size: 1.22rem;
}
.menu-items li {
  padding: 1rem 1.5rem;
  transition: background 0.3s ease-in-out;

}
.menu-items {

  a:hover {
    color: $light-color;
  }



  li:hover {
    background-color: $primary-color;
    border-radius: 10px;
  }
}

/* dropdown */
.dropdown {
  position: relative;

  .dropdown-menu {
    a {
      color: $light-color;
    }
  }

  .menu-item:hover {
    color: set-text-color($primary-color) !important;
  }

}
.dropdown-menu,
.menu-right {
  position: absolute;
  background: $primary-color;
  width: 100%;
  top: 50px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.menu-right {
  top: 0;
  left: 100%;
}
.menu-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.dropdown:hover .dropdown-menu {
  top: 73px;
  opacity: 1;
  visibility: visible;
}
.dropdown-right:hover .menu-right {
  left: 100%;
  opacity: 1;
  visibility: visible;
}

/* mega menu  */
.mega-menu {
  position: absolute;
  left: 0;
  width: 55vw;
  top: 80px;
  left: 345px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  
}
.mega-menu .content {
  background: $primary-color;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
  justify-content: space-between;

  a {
    color: $light-color;
  }
}
.blog .content {
  grid-template-columns: repeat(3, 1fr);
}

.content .col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 3rem;
}
.content .col .img-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 15vw;
  overflow: hidden;
}
.content .col .img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.content .col img {
  width: 100%;
  transition: transform 0.3s ease-in-out;
}
.content .col .img-wrapper:hover img {
  transform: scale(1.1);
}
.content .col h2 {
  color: $light-color;
  font-size: 1.2rem;
  line-height: 3rem;
  font-weight: bold;
}
.content .col p {
  line-height: 1.2rem;
}
.content .col .mega-links {
  border-left: 1px solid $light-color;
}
.content .col .read-more {
  display: inline-block;
  padding-top: 1rem;
  color: #427dad;
  transition: color 0.3s ease;
}
.col .mega-links li,
.col .mega-links li a {
  padding: 0 1rem;
}
.menu-items li:hover .mega-menu {
  top: 85px;
  opacity: 1;
  visibility: visible;
}
.content .col .read-more:hover {
  color: #ff652f;
}

/* section */
.section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.section h1 {
  font-family: "Titillium Web", serif;
  font-weight: 700;
  line-height: 6vw;
  color: #427dad;
  text-transform: uppercase;
}

/* NEW STUFFS */

/* nav menu button */
.menu-btn {
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: 2;
  margin-right: 1rem;

}
.menu-btn__lines::before,
.menu-btn__lines::after {
  content: "";
  position: absolute;

}
.menu-btn__lines,
.menu-btn__lines::before,
.menu-btn__lines::after {
  width: 1.5rem;
  height: 0.1rem;
  background: black;
}
.menu-btn__lines::before {
  transform: translateY(-0.5rem);
}
.menu-btn__lines::after {
  transform: translateY(0.5rem);
}
/* animation */
.menu-btn.open .menu-btn__lines {
  transform: translateX(2rem);
  background: transparent;
}
.menu-btn.open .menu-btn__lines::before {
  transform: rotate(45deg) translate(-1.5rem, 1.5rem);
  background: black;
}
.menu-btn.open .menu-btn__lines::after {
  transform: rotate(-45deg) translate(-1.5rem, -1.5rem);
  background: black;
}

.contact-us {
  margin-left: 1rem;
}

/* MEDIA QUERY */
@media screen and (max-width: 970px) {
  .menu-btn {
    display: flex;
  }

.content .col .img-wrapper {
    height: 100%;
  }

  .nav .menu-items {
    position: absolute;
    height: 100vh;
    width: 100%;
    max-width: 375px;
    top: 100%;
    right: 0;
    background: $light-color;
    display: block;
    padding: 1rem;
    line-height: 3rem;
    overflow-y: auto;
    transform: translateY(-111vh);
    transition: transform 0.3s ease-out;
  }
  .menu-items.open {
    transform: translateY(0);
  }

  .menu-items li {
    margin: 15px 0;
  }
  .menu-items li a {
    padding: 0 0 0 1rem;
    display: block;
    font-size: 1.3rem;
  }
  .menu-items li:hover {
    width: 88vw;
    background-color: $primary-color;
  }

  /* DROPDOWN, MEGA MENUS */
  .menu-items .dropdown-menu,
  .menu-items .menu-right,
  .menu-items .mega-menu {
    position: static;
    opacity: 1;
    top: 4rem;
    visibility: visible;
    // padding-left: 1rem;
    width: 100%;
    max-height: 0;
    transform: scaleY(0);
    transform-origin: top;
    overflow: hidden;
    transition: all 0.2s ease;
  }
  .expand-btn.open + .expandable {
    max-height: 100%;
    transform: scaleY(1);
  }

  .expandable li {
    margin: 0;
  }
  .expandable li a {
    font-size: 1rem;
  }

  .mega-menu {

    
    .content {
      grid-template-columns: auto;
      padding: 0;

      .read-more {
        color: $light-color;
        font-size: 1.25rem;
      }

      h2 {
        font-size: 1.55rem;
      }
  
      p {
        font-size: 1.15rem;
      }
    }
    
  }
  .mega-menu .content .col {
    width: 100%;
    padding-top: 1rem;
    margin-bottom: 0.5rem;
    border-top: 1px solid $light-color;
  }
  .mega-menu .content .col:nth-child(1) {
    border-top: 0px;
  }
  .content .col .mega-links {
    border-left: 0px;
  }
  .col .mega-links li {
    margin: 0;
  }

  .contact-us {
    padding-top: 2rem;
    margin-left: 3.75rem;
  }
}







