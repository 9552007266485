body {
  font-family: $heading-font;

  h1 {
    font-size: $fs-h1;
  }
  
  h2 {
    font-size: $fs-h2;
  }
  
  h3 {
    font-size: $fs-h3;
  }
  
  p {
    font-size: $fs-p;
    font-family: $text-font;
    color: $darker-color;
  }

}

// HomePage

.home-content {

  .home-text {

    h1 {
      font-size: calc(#{$fs-h1 + 1.2rem});
    }

    h3 {
      font-size: calc(#{$fs-h3 + 0.5rem});
    }
  }
}
@media screen and (max-width: 800px) {

  body {
    font-family: $heading-font;
    line-height: 1.2;

    h1 {
      font-size: calc(#{$fs-h1} - 0.5rem);
    }
    
    h2 {
      font-size: calc(#{$fs-h2} - 0.5rem);
    }
    
    h3 {
      font-size: calc(#{$fs-h3} - 0);
    }
    
    p {
      font-size: $fs-p;
      font-family: $text-font;
      color: $darker-color;
      line-height: 1.5;
    }
  
  }
}




