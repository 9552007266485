@import './insider.scss';
@import './blog.scss';
@import './singleblog.scss';
@import './pressR.scss';
@import './press.scss';
@import './media.scss';

.OurWork {
  text-align: center;
}








.SingleVideo {
  margin-top: 2rem;
  overflow: hidden;
  .iframe-container {
    overflow: hidden;
    width: 99.55vw;
    height: 65vh;
    margin: 0 auto;


    iframe {
      background-color: #fff;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  .video-details {
    width: clamp(350px, 100%, 1350px);
    margin: 0 auto;
    h1 {
      font-size: 2rem;
    }
  }
}