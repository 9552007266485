
.Blog {

  h1 {
    text-align: center;
  }

  .blog-container {

    .container {
      margin: 0 auto;

    }

    .blog-categories {
      width: 85%;
      border-top: 3px solid $primary-color;
      padding-top: 1.5rem;
      margin: 0 auto;

      h3 {
        padding: 1.5rem 0 1rem 0;
      }
      .cat-list {

        .cat{
          display: block;
          padding: .6rem 1rem;
          text-decoration: none;
          background-color: transparent;
          border: none;
          outline: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 1rem;
          font-weight: 525;
          margin-bottom: 1rem;
          transition: all .3s ease-in-out;
          
          &:hover{
              background-color: $primary-color;
              color: $light-color;
          }
          &:active{
              background-color: $primary-color;
              color: $light-color;
        
          }
          &:focus{
              background-color: $primary-color;
              color: $light-color;
          }
          
        
        }
      }
    }
  }

  
.search {
  width: 90%;
  position: relative;
  display: flex;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 3px solid $primary-color;
}

.searchTerm {
  width: 100%;
  border: 3px solid $primary-color;
  border-right: none;
  padding: 15px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
}

.searchTerm:focus{
  color: $primary-color;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid $secondary-color;
  background: $secondary-color;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}
}


@media screen and (max-width: 600px) {
  .Blog {

    .flip__grid {
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;

      .card {
        margin-bottom: 1.5rem;

        .content {
          h4 {
            padding: 0.5rem 0;
          }
        }

      }
    }
  }
}