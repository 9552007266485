@import './media.scss';


// .scroll-btn {
//   width: 100%;
//   display: table;

//   a {
//     cursor: pointer;
//   }
  
//   .scroll-down {
//     opacity: 1;
//     -webkit-transition: all .5s ease-in 3s;
//     transition: all .5s ease-in 3s;
//   }
  
//   .scroll-down {
//     position: absolute;
//     bottom: 45px;
//     left: 50%;
//     margin-left: -16px;
//     display: block;
//     width: 43px;
//     height: 43px;
//     // border: 2px solid white;
//     // background-size: 14px auto;
//     // border-radius: 50%;
//     z-index: 2;
//     -webkit-animation: bounce 2s infinite 2s;
//     animation: bounce 2s infinite 2s;
//     -webkit-transition: all .2s ease-in;
//     transition: all .2s ease-in;
//     transform: scale(1)
//   }
  
//   // .scroll-down:before {
//   //     position: absolute;
//   //     top: calc(50% - 8px);
//   //     left: calc(50% - 7px);
//   //     transform: rotate(-45deg);
//   //     display: block;
//   //     width: 20px;
//   //     height: 20px;
//   //     content: "";
//   //     border: 2px solid black;
//   //     border-width: 0px 0 2px 2px;
//   // }
  
//   .scroll-down:after {
//       position: absolute;
//       top: calc(50% - 18px);
//       left: calc(50% - 7px);
//       transform: rotate(-45deg);
//       display: block;
//       width: 20px;
//       height: 20px;
//       content: "";
//       border: 2px solid black;
//       border-width: 0px 0 2px 2px;
//   }
  
//   @keyframes bounce {
//     0%,
//     100%,
//     20%,
//     50%,
//     80% {
//       -webkit-transform: translateY(0);
//       -ms-transform: translateY(0);
//       transform: translateY(0);
//     }
//     40% {
//       -webkit-transform: translateY(-10px);
//       -ms-transform: translateY(-10px);
//       transform: translateY(-10px);
//     }
//     60% {
//       -webkit-transform: translateY(-5px);
//       -ms-transform: translateY(-5px);
//       transform: translateY(-5px);
//     }
//   }
  
  
// }

.HomePage {
  overflow: hidden;
  margin-top: .8rem;
  padding: 1em 0;
}

.home-content {
  // display: flex;
  // position: relative;
  // margin: 0 -1rem;

  // .home-text {
  //   // width: 55%;
  //   position: absolute;
  //   color: $light-color;
  //   // text-align: center;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   z-index: 99;
  // }

    video {
      width: 100vw;
      height: 91.45vh;
      object-fit: cover;
      // border-radius: 8px;
      -webkit-box-shadow: inset -1px 3px 8px 5px #1F87FF, 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0); 
      box-shadow: inset -1px 3px 8px 5px #1F87FF, 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0);
      filter: brightness(55%);
    }

}

// Companies

.Companies {
  margin-bottom: 1rem;
}


// Solutions

.solutions__text {

  h2 {
    padding-bottom: 0;
  }

  h3 {
    text-transform: uppercase;
    color: $gray-color
  }
}

.contact__text {
  
  h2 {
    padding-bottom: 0;
  }

  p {
    font-size: 20px;
    font-weight: 600;
  }
}