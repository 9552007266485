// Counter after element
@each $name, $glyph in $counter-after {  
  .counter-after-#{$name}:after {
    content: $glyph;
    display: inline;
    font-weight: 900;
    font-size: 1.5rem;
  }
}


// Card Icons 

@each $name, $glyph in $icons {  
  .icon-#{$name}:after {
    content: $glyph;
    display: inline;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 17px;
  }
}

// Set Text Color
@function set-text-color($color) {
  @if (lightness($color) > 60) {
    @return $dark-color;
  } @else {
    @return $light-color;
  }
}